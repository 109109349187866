@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 * {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
}

body {
  /* background: #1360AE; */
  background-image: linear-gradient(to right, rgba(255,0,0,0), #1360AE);
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;  
}

body, input, button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.container {
  position: absolute;
    left: 10px;
    bottom: 10px;
  /* margin: 80px auto 0; */
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linha-vertical {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 450px;/*Altura da linha*/
  margin-top: -225px;
  border-left: 2px solid;/* Adiciona borda esquerda na div como ser fosse uma linha.*/
  border-color: #fff;
  box-sizing: border-box;
}

.logoJCA {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 400px;
  border-radius: 4px;
  padding: 30px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* background-color: #ebecec; */
}

.simbolo {
  position: fixed;
  left: 150px;
  top: -50px;
  width: 100px;
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
}

.contentLogin {
  position: absolute;
  left: 75%;
  top: 50%;
  border: 1px solid #999;
  width: 400px;
  background: #ebecec;
  border-radius: 8px;
  padding: 30px;
  padding-top: 70px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.contentLogin > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.contentLogin form {
  display: flex;
  flex-direction: column;
}

.contentLogin form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}

.contentLogin form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.contentLogin form input {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
}

.contentLogin button.btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #f05a5b;
  color: #fff;
  cursor: pointer;
}

.contentLogin button.btn:hover {
  background: #e14f50;
}

.override {
  display: block;
  margin: 0 auto;
  border-color: red;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
  }
  
.ladoALado {
  display: inline-block;
}


/* MODAL */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
    .modal-main {
      position:fixed;
      background: white;
      width: 80%;
      height: auto;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
    }
ul.Acoes {
    position: absolute;
    bottom: 10px;
}
 
.actionBackground {
  background-color:#222d32;
}

.elementoGrid {
    border: 1px solid black;
}

.tabelawidgets {
  width: 90%;
  height: 400px;
  overflow-y: auto;
}

.scroll {
  overflow: hidden;
  overflow: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.botaowidget {
  z-index: 99;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.card {
  position: relative;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  background-color: #fff; 
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 3rem;
  margin-top: 20px;
  padding: 10px;
  height: 300px;
}

.buttonHover {
  background-color: transparent
}

.buttonHover:hover {
  background-color: rgb(255, 255, 255);
}

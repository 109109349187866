ul.Acoes {
    position: absolute;
    bottom: 10px;
}
 
.actionBackground {
  background-color:#222d32;
}

.elementoGrid {
    border: 1px solid black;
}

.tabelawidgets {
  width: 90%;
  height: 400px;
  overflow-y: auto;
}

.scroll {
  overflow: hidden;
  overflow: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.botaowidget {
  z-index: 99;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  word-wrap: break-word;
  background-color: #fff; 
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 3rem;
  margin-top: 20px;
  padding: 10px;
  height: 300px;
}

.buttonHover {
  background-color: transparent
}

.buttonHover:hover {
  background-color: rgb(255, 255, 255);
}